<template>
  <Anonymous>
    <b-overlay :show="showOverlay" rounded="sm">

      <div class="form">
        <div class="row">
          <div class="col logo">
            <BigLogo />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3 class="logo-subheader">
              Smart. Simplified. Streamlined.
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="password-recovery-form-header">Password recovery</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12" v-if="!emailSent">
            <div class="sub-title">
              Please enter the email address you have associated <br />with your account.
            </div>
            <b-form @submit="onSubmit" class="mt-4 login-form">
              <div role="group" class="form-group-input">
                <b-form-input
                    id="password-recovery-email"
                    v-model="form.email"
                    autocomplete="username"
                    type="text"
                    :state="validateState('email')"
                    placeholder="Your email"></b-form-input>
                <b-form-invalid-feedback id="input-email-feedback">
                  <div v-if="!$v.form.email.email">The email is not valid</div>
                  <div v-else-if="!$v.form.email.required">The email is required</div>
                </b-form-invalid-feedback>
              <div class="mt-4 d-flex">
                <div class="flex-fill">
                  <b-button type="submit" variant="primary">Send instructions</b-button>
                </div>
                <div class="flex-fill d-flex align-items-center justify-content-end">
                  <router-link to="/login" class="forgot-password-link">Back to login</router-link>
                </div>
              </div>
              </div>
            </b-form>

          </div>
        </div>


        <div class="row" v-if="emailSent">
          <div class="col-12 mt-1" >
            <p>An email has been sent to {{ form.email }}
              <br /> with instructions on how to reset your password.</p>
            <p>Check your email and click on the link provided. <br />
              If you don't hear from us in the next 15 minutes,<br />
              please check your spam folder.</p>
            <p>
              <router-link to="/login" class="btn btn-primary">Back to login</router-link>
            </p>
          </div>
        </div>

      </div>


    </b-overlay>

  </Anonymous>
</template>

<script>
import Anonymous from "../layouts/Anonymous";
import {validationMixin} from 'vuelidate';
import {required, email} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import BigLogo from "../components/BigLogo";

export default {
  name: "PasswordRecovery",
  components: {Anonymous, BigLogo},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: ''
      },
      emailSent: false,
      showOverlay: false
    }
  },
  computed: {
    ...mapGetters("errors", ["hasErrors"]),
  },
  validations: {
    form: {
      email: {
        required, email
      }
    }
  },
  methods: {
    ...mapActions("auth", ["passwordRecovery"]),
    onSubmit(event) {
      event.preventDefault()
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.showOverlay = true;
      this.passwordRecovery(this.form).then(() => {
        if (!this.hasErrors) {
          this.emailSent = true;
        }
        this.showOverlay = false;
      });
    },
    validateState(name) {
      if (this.hasErrors) {
        return false;
      }
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  }
}
</script>

<style scoped>

</style>